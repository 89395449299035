import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store/store'



const routes = [
  {
    path: `/login`,
    name: 'step-1',
    component: () => import('@/views/Login.vue'), 
    meta: {
      requireGuest: true,
    }
  },
  {
    path: `/:id?`,
    name: 'step0',
    component: () => import('@/views/Home.vue'), 
    meta: {
      requireAuth: true,
    }
  },
  {
    path: `/Learning-Objectives`,
    name:'step1',
    component: () => import('@/views/LearningObjectives.vue'), 
    meta: {
      requireAuth: true,
    }
  },
  {
    path: `/Pre-Requisites`,
    name: 'step2',
    component: () => import('@/views/Pre-Requisites.vue'), 
    meta: {
      requireAuth: true,
    }
  },
  {
    path: `/Patient-History`,
    name: 'step3',
    component: () => import('@/views/PatientHistory.vue'), 
    meta: {
      requireAuth: true,
    }
  },
  {
    path: `/Present-History`,
    name: 'step4',
    component: () => import('@/views/HistoryTaking.vue'), 
    meta: {
      requireAuth: true,
    }
  },
  {
    path: `/Past-History`,
    name: 'step5',
    component: () => import('@/views/HistoryTaking.vue'), 
    meta: {
      requireAuth: true,
    }
  },
  {
    path: `/Family-History`,
    name: 'step6',
    component: () => import('@/views/HistoryTaking.vue'), 
    meta: {
      requireAuth: true,
    }
  },
  {
    path: `/Menstrual-History`,
    name: 'step7',
    component: () => import('@/views/HistoryTaking.vue'), 
    meta: {
      requireAuth: true,
    }
  },
  {
    path: `/Obstetric-History`,
    name: 'step8',
    component: () => import('@/views/HistoryTaking.vue'), 
    meta: {
      requireAuth: true,
    }
  },
  {
    path: `/Family-Planning-History`,
    name: 'step9',
    component: () => import('@/views/HistoryTaking.vue'), 
    meta: {
      requireAuth: true,
    }
  },
  {
    path: `/Physical-Examination`,
    name: 'step10',
    component: () => import('@/views/PhysicalExamination.vue'), 
    meta: {
      requireAuth: true,
    }
  },
  {
    path: `/State/:id?`,
    name: `step11`,
    component: () => import('@/views/State.vue'), 
    meta: {
      requireAuth: true,
    }
  },
  {
    path: `/Case-Revision/:id?`,
    name: `step12`,
    component: () => import('@/views/CaseRevision.vue'), 
    meta: {
      requireAuth: true,
    }
  },
  {
    path: `/Assessment`,
    name: `step13`,
    component: () => import('@/views/Assessment.vue'), 
    meta: {
      requireAuth: true,
    }
  },
  {
    path: `/Medical-Orders-Lab-Investigation`,
    name: `step14`,
    component: () => import('@/views/MedicalOrders.vue'), 
    meta: {
      requireAuth: true,
    }
  },
  {
    path: `/Medical-Orders-Diagnostic-Procedure`,
    name: `step15`,
    component: () => import('@/views/MedicalOrders.vue'), 
    meta: {
      requireAuth: true,
    }
  },
  {
    path: `/Medical-Orders-Instructions`,
    name: `step16`,
    component: () => import('@/views/MedicalOrders.vue'), 
    meta: {
      requireAuth: true,
    }
  },
  {
    path: `/Medical-Orders-Medication`,
    name: `step17`,
    component: () => import('@/views/MedicalOrders.vue'), 
    meta: {
      requireAuth: true,
    }
  },
  {
    path: `/Supplies`,
    name: `step18`,
    component: () => import('@/views/Supplies.vue'), 
    meta: {
      requireAuth: true,
    }
  },
  {
    path: `/ICU`,
    name: `step19`,
    component: () => import('@/views/ICU.vue'), 
    meta: {
      requireAuth: true,
    }
  },
  {
    path: `/Clinical-Assessment`,
    name: `step20`,
    component: () => import('@/views/ClinicalAssessment.vue'), 
    meta: {
      requireAuth: true,
    }
  },
  {
    path: `/conclusion`,
    name: `step21`,
    component: () => import('@/views/Conclusion.vue'), 
    meta: {
      requireAuth: true,
    }
  },
  {
    path: `/404`,
    component: () => import('@/views/Page-404.vue'), 
  }
  
]



const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


export default router
