import { createStore } from 'vuex'
import axios from '@/axios'
import router from '@/router'

 const store = createStore({
  state: {
    load: false,
    instructions: null,
    trial_id: null,
    trial_state_id: null,
    userToken:  localStorage.getItem('userToken') || null,
    otp: null,
    step: 0,
    stateStep: 0,
    currentState: 0,
    case_key: null,
    navigationStatus: true,
    nextNavigationStatus: true,
    prevNavigationStatus: true,
    expData: [],
    stateData:[],
    intro: [],
    learning_objectives: [],
    pre_requisites: [],
    patient_history: [],
    present_history: [],
    past_history: [],
    family_history: [],
    menstrual_history: [],
    obstetric_history: [],
    family_planning_history: [],
    physical_examination:[],
    assessment: [],
    MO_Investigation: [],
    MO_DiagnosticProcedure: [],
    MO_Instructions: [],
    MO_Medication: [],
    supplies: [],
    hotspots: [], 
    clinical_assessment: [],
    conclusion: [],
    loginError: null,
    times:[],
    start_time: null,
    end_time: null,
    time_per_step: null,
    hotspotsOrder: null,
  },
  getters:{
    case_key: state => state.case_key,
    expData: state => state.expData,
    stateData: state => state.stateData,
    load: state => state.load,
    intro: state => state.intro,
    learningObjectives: state => state.learning_objectives,
    preRequisites: state => state.pre_requisites,
    patientHistory: state => state.patient_history,
    presentHistory: state => state.present_history,
    pastHistory: state => state.past_history,
    familyHistory: state => state.family_history,
    menstrualHistory: state => state.menstrual_history,
    obstetricHistory: state => state.obstetric_history,
    familyPlanningHistory: state => state.family_planning_history,
    physicalExamination: state => state.physical_examination,
    assessment: state => state.assessment,
    MOInvestigation: state => state.MO_Investigation,
    MODiagnosticProcedure: state => state.MO_DiagnosticProcedure,
    MOInstructions: state => state.MO_Instructions,
    MOMedication: state => state.MO_Medication,
    supplies: state => state.supplies,
    hotspots: state => state.hotspots,
    clinicalAssessment: state => state.clinical_assessment,
    conclusion: state => state.conclusion,
    currentState: state => state.currentState,
    hotspotsOrder: state => state.hotspotsOrder
  },
  mutations: {
    SET_EXPERIMENT_DATA(state, expData){
      state.expData = expData
    },
    SET_LOAD(state, load){
      state.load = load
    },
    SET_INTRO(state, intro){
      state.intro = intro
    },
    SET_LEARNING_OBJECTIVES(state, learning_objectives){
      state.learning_objectives = learning_objectives
    },
    SET_PRE_REQUISITES(state, pre_requisites){
      state.pre_requisites = pre_requisites
    },
    SET_PATIENT_HISTORY(state, patient_history){
      state.patient_history = patient_history
    },
    SET_PRESENT_HISTORY(state, present_history){
      state.present_history = present_history
    },
    SET_PAST_HISTORY(state, past_history){
      state.past_history = past_history
    },
    SET_FAMILY_HISTORY(state, family_history){
      state.family_history = family_history
    },
    SET_MENSTRUAL_HISTORY(state, menstrual_history){
      state.menstrual_history = menstrual_history
    },
    SET_OBSTETRZIC_HISTORY(state, obstetric_history){
      state.obstetric_history = obstetric_history
    },
    SET_FAMILY_PLANNING_HISTORY(state, family_planning_history){
      state.family_planning_history = family_planning_history
    },
    SET_PHYSICAL_EXAMINATION(state, physical_examination){
      state.physical_examination = physical_examination
    },
    SET_ASSESSMENT(state, assessment){
      state.assessment = assessment
    },
    SET_MEDICALORDERS_LAB_INVESTIGATION(state, MO_Investigation){
      state.MO_Investigation = MO_Investigation
    },
    SET_MEDICALORDERS_DIAGNOSTIC_PROCEDURE(state, MO_DiagnosticProcedure){
      state.MO_DiagnosticProcedure = MO_DiagnosticProcedure
    },
    SET_MEDICALORDERS_INSTRUCTIONS(state, MO_Instructions){
      state.MO_Instructions = MO_Instructions
    },
    SET_MEDICALORDERS_MEDICATION(state, MO_Medication){
      state.MO_Medication = MO_Medication
    },
    SET_SUPPLIES(state, supplies){
      state.supplies = supplies
    },
    GET_HOTSPOTS(state, hotspots){
      state.hotspots = hotspots
    },
    SET_CLINICAL_ASSESSMENT(state, clinical_assessment){
      state.clinical_assessment = clinical_assessment
    },
    SET_CONCLUSION(state, conclusion){
      state.conclusion = conclusion
    },
    SET_CURRENT_STATE(state, currentState){
      state.currentState = currentState
    },
    SET_NAVIGATION_STATUS(state, navigationStatus){
      state.navigationStatus = navigationStatus
    },
    SET_NAVIGATION_PREV(state, prevNavigationStatus){
      state.prevNavigationStatus = prevNavigationStatus
    },
    SET_NAVIGATION_NEXT(state, nextNavigationStatus){
      state.nextNavigationStatus = nextNavigationStatus
    },
    SET_STATE_DATA(state, stateData){
      state.stateData = stateData
    },
    SET_TRIAL_ID(state, trial_id){
      state.trial_id = trial_id
    },
    SET_TRIAL_STATE_ID(state, trial_state_id){
      state.trial_state_id = trial_state_id
    },
    SET_HOTSPOTS_ORDER_CHECK(state, hotspotsOrder){
      state.hotspotsOrder = hotspotsOrder
    },
    SET_INSTRUCTIONS(state, instructions){
      state.instructions = instructions
    }, 
    SET_OTP(state, otp){
      state.otp = otp
    },
    SET_TIMES(state, times){
      state.times = times
    },
  },
  actions: {

    next(){
      // prevent Navigator if the navigationStatus is false
      if ( this.state.navigationStatus == false || this.state.nextNavigationStatus == false) {
        return false;
      }
      //skip case revision component in the first State ( ["currentState"] == 0 ) 
      if ( router.currentRoute.value.name == "step11" && this.getters["currentState"] == 0 ) {
        this.state.step += 2;
        this.state.stateStep += 1;
        router.push({ name: `step${this.state.step}`, query: {'exp': this.state.case_key} });
      } 
      else {
        this.state.step += 1;
        this.state.stateStep += 1;
        if ( this.getters["currentState"] == this.getters["expData"][0].num_of_states ) {
          this.state.step = 21;
          router.push({ name: `step${this.state.step}`, query: {'exp': this.state.case_key} });
        } 
        else if ( router.currentRoute.value.name == "step20" ) {
          this.state.step = 11;
          router.push({ name: `step${this.state.step}`, query: {'exp': this.state.case_key} });
        }
        router.push({ name: `step${this.state.step}`, query: {'exp': this.state.case_key} });
      }
    },

    prev(){
      // prevent Navigator if the navigationStatus is false
      if (this.state.navigationStatus == false || this.state.prevNavigationStatus == false) 
      {
        return false;
      }
      //skip case revision component in the first State ( ["currentState"] == 0 ) 
      if ( router.currentRoute.value.name == "step13" && this.getters["currentState"] == 0 ) {
        this.state.step -= 2;
        this.state.stateStep -= 1;
        router.push({ name: `step${this.state.step}`, query: {'exp': this.state.case_key} });
      } 
      else {
        if ( this.state.step == 0 ) {
          router.push({ path: `/`, query: {'exp': this.state.case_key} });
        } 
        else {
          this.state.step -= 1;
          router.push({ name: `step${this.state.step}`, query: {'exp': this.state.case_key} });
        }
      }
    },

    login({ commit }, payload){
        commit("SET_LOAD", true)

        return new Promise((resolve, reject) => {

          axios.post(`/api/login`, payload ).then(response => {
                if (response.data.access_token) {
                  this.state.userToken = response.data.access_token
                  // store user details and jwt token in local storage to keep user logged in between page refreshes
                  localStorage.setItem('userToken', response.data.access_token );
                  // router.push({ path: `/`, query: {'exp': store.state.case_key} });
                  resolve(response) ;
              }
            }).then(_=>{
              commit("SET_LOAD", false)
            }).catch(error => {
              this.state.otp = null;
              this.state.loginError = error
              reject(error)
            })

        })
         

        commit("SET_LOAD", false)
    },

    logout(){
      localStorage.removeItem("userToken");
      this.state.userToken = null
      this.state.step = 0
      this.state.currentState = 0
      this.state.stateStep = 0
      router.push({ name: `step-1`, query: {'exp': this.state.case_key} });
    },

    async getCaseData({ commit }, id){
      commit("SET_LOAD", true)
      await axios.get(`/api/get_case/${id}`,{
        headers: {"Authorization" : `Bearer ${this.state.userToken}`}
      }).then(response => {
        commit("SET_EXPERIMENT_DATA", response.data.data)
        commit("SET_INTRO", response.data.data[0])
        commit("SET_TRIAL_ID", response.data.data[0].trial_id)
        commit("SET_LEARNING_OBJECTIVES", response.data.data[1])
        commit("SET_PRE_REQUISITES", response.data.data[2])
        commit("SET_PATIENT_HISTORY", response.data.data[3])
        commit("SET_PRESENT_HISTORY", response.data.data[4])
        commit("SET_PAST_HISTORY", response.data.data[5])
        commit("SET_FAMILY_HISTORY", response.data.data[6])
        commit("SET_MENSTRUAL_HISTORY", response.data.data[7])
        commit("SET_OBSTETRZIC_HISTORY", response.data.data[8])
        commit("SET_FAMILY_PLANNING_HISTORY", response.data.data[9])
        commit("SET_PHYSICAL_EXAMINATION", response.data.data[10])
        commit("SET_CONCLUSION", response.data.data[11])
      }).then(_=>{
        commit("SET_LOAD", false)
      }).catch(error => {
        if(error.response.status != 401){
          router.push({ path: `/404` });
        }
        else{
          // router.push({ path: `/login` });
          localStorage.removeItem("userToken");
          console.log('dddddddddddd');
        }
      })
    },

    async getStateData({ commit }, id){
      
      commit("SET_LOAD", true)
      await axios.post(`/api/get_case_state/${id}`, { trial_id: this.state.trial_id },
      {
        headers: {"Authorization" : `Bearer ${this.state.userToken}`}
      }).then(response => {
        commit("SET_STATE_DATA", response.data.data)
        commit("SET_PATIENT_HISTORY", response.data.data[0])
        commit("SET_TRIAL_STATE_ID", response.data.data[0].trial_state_id)
        commit("SET_HOTSPOTS_ORDER_CHECK", response.data.data[0].hotspots_order)
        commit("SET_ASSESSMENT", response.data.data[1])
        commit("SET_MEDICALORDERS_LAB_INVESTIGATION", response.data.data[2])
        commit("SET_MEDICALORDERS_DIAGNOSTIC_PROCEDURE", response.data.data[3])
        commit("SET_MEDICALORDERS_INSTRUCTIONS", response.data.data[4])
        commit("SET_MEDICALORDERS_MEDICATION", response.data.data[5])
        commit("SET_SUPPLIES", response.data.data[6])
        commit("GET_HOTSPOTS", response.data.data[7])
        commit("SET_CLINICAL_ASSESSMENT", response.data.data[8])
      }).then(_=>{
        this.state.stateStep = 0;
        commit("SET_LOAD", false)
      })
    },

    supplies({ commit }, supplies){
      commit("SET_LOAD", true)
      axios.post(`/api/store_trial_state_supplies`, { supplies , trial_state_id: this.state.trial_state_id }, {
        headers: {"Authorization" : `Bearer ${this.state.userToken}`}
      }).then(response => {
        commit("SET_LOAD", false)
      })
      commit("SET_LOAD", false)
    },

    hotspot({ commit }, allOpnnedHotspots){
      commit("SET_LOAD", true)
      axios.post(`/api/store_trial_state_hotspots`, { hotspots: allOpnnedHotspots , trial_state_id: this.state.trial_state_id }, {
        headers: {"Authorization" : `Bearer ${this.state.userToken}`}
      }).then(response => {
        commit("SET_LOAD", false)
      })
      commit("SET_LOAD", false)
    },

    clinicalAssessment({ commit }, payload){
      commit("SET_LOAD", true)
      axios.post(`/api/store_trial_state_questions`, { result: payload.status , answers: payload.answers , trial_state_id: this.state.trial_state_id }, {
        headers: {"Authorization" : `Bearer ${this.state.userToken}`}
      }).then(response => {
        commit("SET_LOAD", false)
      })
      commit("SET_LOAD", false)
    },

    trialTime({ commit }){
      commit("SET_LOAD", true)
      axios.post(`/api/store_trial_times`, { trial_times: this.state.times , trial_id: this.state.trial_id }, {
        headers: {"Authorization" : `Bearer ${this.state.userToken}`}
      }).then(response => {
        commit("SET_LOAD", false)
      })
      commit("SET_LOAD", false)
    },

    setStartTime({commit}){
      var date = new Date();
      this.state.start_time = date.getTime() / 1000;
    },

    setEndTime({commit}, comp_name){
      var date = new Date();
      this.state.end_time = date.getTime() / 1000;
      this.state.time_per_step = this.state.end_time - this.state.start_time
      this.state.times.push({step_name: comp_name, time: this.state.time_per_step})
    },

    setCurrentState({commit}){
      commit("SET_CURRENT_STATE", ++this.state.currentState)
    },

    setInstructions({commit}, instructions){
      commit("SET_INSTRUCTIONS", instructions)
    },

    denyNavigationStatus({commit}){
      commit("SET_NAVIGATION_STATUS", false)
    },

    allowNavigationStatus({commit}){
      commit("SET_NAVIGATION_STATUS", true)
    },

    prevNavigationStatus({commit}, status){
      commit("SET_NAVIGATION_PREV", status)
    },

    nextNavigationStatus({commit}, status){
      commit("SET_NAVIGATION_NEXT", status)
    },

    setOTP({commit}, otp){
      commit("SET_OTP", otp)
    },
    
  }
})

export default store
