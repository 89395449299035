<template>
  <div id="app">
    <div id="header" class="d-flex justify-content-between">
      <div class="logo-container pt-2">
        <img alt="dll" class="dll" src="~@/assets/images/dll.svg" />
      </div>
      <div class="header-bar w-100">
        <div class="container h-100">
          <div class="row h-100">
            <div class="col-1 col-md-1"></div>
            <div
              class="
                col-9
                col-md-10
                h-100
                pt-2
                pt-md-1
              "
            >
              <h2
                class="caseName mr-5 text-capitalize text-center"
                v-if="this.$store.state.step > 0"
              >
                {{ this.$store.getters["intro"].case_name }}
              </h2>
            </div>
            <div class="col-1 col-md-1 pt-2 pt-md-1 d-flex">
              <h2
                class="caseName mr-5 text-capitalize text-center cursor-pointer"
                data-toggle="tooltip" title="maximize"
                style="margin-right: 20px"
                @click="maximize()"
              >
                <i class="fas fa-expand-arrows-alt"></i>
              </h2>
              <h2
                class="caseName mr-5 text-capitalize text-center cursor-pointer"
                data-toggle="tooltip" title="Logout"
                @click="logout()"
                v-if="this.$route.name != 'step-1' && this.$route.path != '/404'"
              >
                <i class="fas fa-sign-out-alt" ></i>
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="body">
      <router-view />
    </div>
     
    <div id="footer" class="d-flex justify-content-between">
      <div class="footer-bar w-100">
        <img src="~@/assets/images/footer.png" alt="" />
      </div>
    </div>

    <div class="nav w-100 d-flex justify-content-center">
      <div class="container-fluid">
        <div class="row h-100">
          
          <div class="col-4 d-flex justify-content-center align-items-center" style="color: #ff7e7e; font-weight: 600;">
              <div class="instruction">
                <p>{{ this.$store.state.instructions }}</p>
              </div>
          </div>

          <div class="col-4">
            <div class="nav w-100 d-flex justify-content-center">
              <div
                class="btn nav-btn-wrapper"
                @click="prev"
                :disabled="this.$store.state.step <= 0"
                v-if="this.$store.state.step > 0"
              >
              <div :class="this.$store.state.navigationStatus == false || this.$store.state.prevNavigationStatus == false ? 'button prev-btn cursor-nodrop disable-hover-effect' : 'button prev-btn'">
                <div class="dub-arrow">
                  <img src="@/assets/images/arrow.png" alt="" />
                </div>
                <a class="a-btn" >Back</a>
              </div>
              </div>
              <div
                class="btn nav-btn-wrapper"
                @click="next"
                
                :disabled="
                  this.$store.state.step >=
                  this.$router.options.routes.length - 1
                "
                v-if="this.$store.state.step > 0"
              >
                <div :class="this.$store.state.navigationStatus == false || this.$store.state.nextNavigationStatus == false ? 'button next-btn cursor-nodrop disable-hover-effect' : 'button next-btn'">
                  <div class="dub-arrow">
                    <img src="@/assets/images/arrow.png" alt="" />
                  </div>
                  <a class="a-btn" >Next</a>
                </div>
              </div>
            </div>
          </div>

          <div class=" col-4 copyRight d-flex justify-content-center align-items-center">
            <p>Copyright Ain Shams University, <a href="https://dsdc.eng.asu.edu.eg/digital-learning-lab" target="_blank">DLL</a> , <span id="year"></span>.</p>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      isEditing: false
    };
  },
  methods: {
  
    prev() {
      this.$store.dispatch('prev')
    },
    next() {
      this.$store.dispatch('next')
    },
    maximize(){
        var doc = window.document;
        var docEl = doc.documentElement;

        var requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
        var cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;

        if(!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
        requestFullScreen.call(docEl);
        }
        else {
        cancelFullScreen.call(doc);
        }
    },
    logout(){
      console.log('ssssssssssssssssssssssssssssss');
      localStorage.removeItem("userToken");
      this.$store.dispatch( 'setOTP', null)
      this.$store.state.userToken = null
      this.$store.state.step = 0
      this.$store.state.currentState = 0
      this.$store.state.stateStep = 0
      this.$router.push({ name: `step-1`, query: {'exp': this.$store.state.case_key} });
      this.$store.dispatch('logout')
    }
  },
  computed: {
    loadHandler() {
      return this.$store.state.load
    },
    reloadHandler(){
      return this.$store.state.step
    }
  },
  watch:{
    loadHandler(val, oldVal) {
      if(val == true){
        this.$store.dispatch('nextNavigationStatus', false)
      }
      else{
        this.$store.dispatch('nextNavigationStatus', true)
      }
    },
    reloadHandler(val, oldVal){
      if (val >= 8) {
        window.addEventListener('beforeunload', (event) => {
            event.preventDefault();
            event.returnValue = '';  
        });
      }
    }
  },
  mounted() {

    // this.$store.dispatch('next')

    //get the case key
    this.$store.state.case_key = new URL(location.href).searchParams.get('exp');
    this.$store.state.otp = new URL(location.href).searchParams.get('otp');

    if(this.$store.state.case_key == null){
      this.$store.state.step = -1;
    }
    
    //get the case data only if the user is authenticated
    if(this.$store.state.userToken != null){ 
      this.$store.dispatch("getCaseData", this.$store.state.case_key)
    } 
    
    //if the user refreshed the page at any step, he will direct back to the home
    if(this.$store.state.step == 0){ 
      this.$router.push({ name: `step${this.$store.state.step}`, query: {'exp': this.$store.state.case_key} });
    }

    //handle the refresh  action
    function disableF5(e) {
      if ((e.which || e.keyCode) == 116) e.preventDefault();
      if (e.keyCode == 82 && e.ctrlKey) e.preventDefault();
    }
    $(document).on("keydown", disableF5);
    document.getElementById("year").innerHTML = new Date().getFullYear();

  }
};
</script>

<style>
@import "./main.css";

#header {
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}

.header-bar {
  background: url("~@/assets/images/header.png") no-repeat;
  background-size: cover;
  height: 70px;
}

#footer {
  position: absolute;
  bottom: 0;
  right: 0;
}



.alert {
  border: 0px solid transparent;
  border-radius: unset;
  border-top-right-radius: 0.5rem;
}

.footer-bar {
  position: relative;
  height: 60px;
}

.footer-bar img {
  height: 100%;
}

#body {
  overflow: auto;
  height: calc(100%);
}

#body::-webkit-scrollbar {
  width: 12px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #002148;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #48D8D8;
}

#app {
  position: relative;
  overflow: hidden;
  height: 100vh;
}

.dll {
  width: 150px;
  height: auto;
}

.logo-container {
  padding-left: 20px;
}

.nav {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 60px;
}

.page-number {
  color: #fff;
}

.page-number p {
  margin-bottom: 0.4rem !important;
}

.nav-icon {
  font-size: 20px;
  line-height: unset !important;
}

.nav-btn-wrapper{
  position: relative;
  margin-top: -25px;
}

.button {
  display: inline-flex;
  height: 40px;
  width: 130px;
  border-top: 2px solid #002148;
  border-bottom: 2px solid #fff;
  background-color: #48d8d8;
  border-radius: 150px;
  color: #002148;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 20px;
  letter-spacing: 1.5px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.a-btn {
  color: #fff;
  text-decoration: none;
  letter-spacing: 1px;
}

.button {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.button img {
  width: 20px;
  height: auto;
}

.next-btn .dub-arrow {
  width: 100%;
  height: 100%;
  background: #002148;
  left: -200px;
  position: absolute;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .35s ease-Out;
  bottom: 0;
}

.next-btn a {
  position: relative;
  left: 0;
  transition: all .35s ease-Out;
}

.next-btn:hover .dub-arrow {
  left: 0;
}

.next-btn:hover a {
  left: 150px;
}

.prev-btn .dub-arrow {
  width: 100%;
  height: 100%;
  background: #002148;
  right: -200px;
  position: absolute;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .35s ease-Out;
  bottom: 0;
}

.prev-btn a {
  position: relative;
  right: 0;
  transition: all .35s ease-Out;
}

.prev-btn:hover .dub-arrow {
  right: 0;
}

.prev-btn:hover a{
  right: 150px;
}

.disable-hover-effect:hover .dub-arrow {
  right: -200px;
  left: -200px;
}

.disable-hover-effect:hover a{
  right: unset;
  left: unset;
}
.disable-hover-effect .a-btn{
  color: gray !important;
}

.prev-btn img {
  transform: scaleX(-1);
}

.help img {
  width: 50px;
  margin-top: -20px;
  cursor: pointer;
}

.copyRight p {
  color: rgb(202, 202, 202);
  font-size: 0.8rem;
  z-index: 999 !important;
}

.caseName {
  color: #fff;
  margin-top: 8px;
}

@media (max-width: 992px) {
  .caseName {
    margin-right: 0px;
    font-size: 1.3rem;
  }

  #body::-webkit-scrollbar {
    display: none;
  }
}
@media (max-width: 768px) {
  #body {
    overflow: scroll;
    height: calc(100%);
  }
  #body::-webkit-scrollbar {
    display: none;
  }
  .logo-container {
    padding-left: 5px;
    padding-top: 5px !important;
  }
  .dll {
    width: 60px;
    height: auto;
  }
  .header-bar {
    height: 43px;
  }
  .header-bar .container .row{
    padding: 0px !important;
  }
  .footer-bar {
    height: 33px;
  }
  .nav {
    height: 33px;
  }

  .nav-btn-wrapper{
    margin-top: -5px;
  }


  /* .nav-btn-wrapper > img {
    max-width: 60px;
  } */

  /* .nav-btn-wrapper > span {
    position: absolute;
    left: 0px;
    right: 0px;
    margin: auto;
    top: 12px;
    bottom: 0px;
    font-size: 10px;
    letter-spacing: 3px;
    color: #fff;
    font-weight: 600;
  } */

  .button{
    max-width: 60px;
    max-height: 20px;
    font-size: 10px;
  }

  .copyRight p {
    color: rgb(202, 202, 202);
    font-size: 0.3rem;
    margin-right: 15px;
  }

  .help img {
    width: 20px;
    margin-top: 5px;
    float: left;
  }

  .caseName {
    color: #fff;
    margin-right: 0px;
    font-size: 10px;
  }

}
</style>
