import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import 'jquery'
import '@popperjs/core'
import 'bootstrap'
import '@fortawesome/fontawesome-free'
import AOS from 'aos'
import 'aos/dist/aos.css'

global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;



router.beforeEach((to, from, next) => {
 
    if(to.matched.some(record => record.meta.requireAuth)){ //olny the authenticated users
      if(!store.state.userToken){
        localStorage.removeItem("userToken");
        next({
          path: '/login',
          query: { redirect: to.fullPath}
        })
      }else{
        next()
      } 
    }else if(to.matched.some(record => record.meta.requireGuest)){ //prevent the logged in user to access the login page
      if(store.state.userToken){
        next({
          path: '/',
          query: { redirect: to.fullPath}
        })
      }else{
        next()
      } 
    }else{
        next()
    }
      
    
  
})

createApp(App).use(store).use(router).use(AOS.init()).mount('#app')
